/**
 *Created By Barbaros Susuz on Jun, 2019
 **/
import {debounce, pickBy} from "lodash";
import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Error from "../_error";
import Main from "../../layout/Main";
import GoogleAd from "../../components/GoogleAd";
import HoverableContentPoster from "../../components/HoverableContentPoster";
import {getContentsPageMeta} from "@/helper/meta";
import {moviesGetByFilter} from "@/services";
import {NUMBER_LIST_DEFAULT} from "@/helper/constants";
import {turnFilterQueryToApiObjects} from "@/helper/common";
import {animatedScrollTop} from "@/helper/componentHelper";
import type {NextPageContext} from "next";
import type {MetaType} from "@/types/dataType";
import type {ContentType} from "@/types/contentType";
import {redirectContextToStatus} from "@/helper/auth";

function Contents({errorPage, meta}: { errorPage: boolean, meta: MetaType }) {
  const firstUpdate = useRef(true);
  const queryTimeout = useRef();
  const {query, asPath} = useRouter();
  const loading = useRef(false);
  const lastScrollY = useRef(0);

  const dispatch = useDispatch();
  const data: ContentType[] = useSelector((state: any) => state.generalReducer.data);
  const allDataCount = useSelector((state: any) => state.generalReducer.allDataCount);
  const numbers: typeof NUMBER_LIST_DEFAULT = useSelector((state: any) => state.generalReducer.filters.numbers);
  const skip = useSelector((state: any) => state.generalReducer.skip);
  const getDataWithResetDebounce = useCallback(debounce(getData, 500), [query, numbers]);

  useEffect(() => {
    // scrollRestoration yuzunden asenkron calismali, yoksa scroll tetikleniyor
    setTimeout(() => {
      document.addEventListener("scroll", onScroll);
    }, 0);
    return () => {
      document.removeEventListener("scroll", onScroll);
      clearTimeout(queryTimeout.current);
      getDataWithResetDebounce.cancel();
    };
  }, [skip, numbers]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      getDataWithResetDebounce(true);
    }
  }, [query, numbers]);

  if (errorPage) return <Error statusCode={404}/>;

  return (
    <Main meta={getContentsPageMeta({asPath: asPath, query})} showAds>
      <div className="googlead-home-top">
        <GoogleAd slot={"iceriklerTop"}/>
      </div>
      <section className="container-fluid container-max-width pt-3">
        <h1
          className="main-page-warning"
          style={{position: "absolute", opacity: 0, zIndex: -1}}
        >
          Nerede izlenir, günümüzde sayıları git gide artan streaming
          platformları arasında izlemek istediğiniz içeriğin hangisinde var diye
          tek tek app gezmeden,{" "}
          <span className="platform">iMDB, MetaCritic, RottenTomato</span>{" "}
          puanlarıyla birlikte (Bunu da başka yerde bulamazsınız ha 😉) sizlere
          servis eder. Sizi çöp içerikten korur.
        </h1>
        <p className="data-count">
          {allDataCount} <span>adet içerik listelendi</span>
        </p>
        <div className="grid-card-list">
          {data.map((i, index) => (
            <HoverableContentPoster key={`${i.id}`} content={i}/>
          ))}
        </div>
        {!data.length &&
          <div className='d-flex justify-content-center align-items-center h-100'>
            Seçtiğin filtrelerle herhangi bir içerik bulamadık, filtreleri değiştirip tekrar deneyebilirsin.
          </div>
        }
      </section>
    </Main>
  );

  function onScroll(e: any) {
    const scrollHeight = e.target.body.scrollHeight;
    const scrollY = window.scrollY;
    const screenHeight = window.screen.height;
    const lastPosition = scrollHeight - screenHeight;

    if (
      !firstUpdate.current &&
      lastScrollY.current < scrollY &&
      scrollY + screenHeight > lastPosition &&
      !loading.current &&
      data.length < allDataCount
    ) {
      getData();
    }
    lastScrollY.current = scrollY;
  }

  async function getData(resetSkip?: boolean) {
    loading.current = true;
    const response = await moviesGetByFilter({
      pageSize: 50,
      page: resetSkip ? 1 : skip,
      ...turnFilterQueryToApiObjects(),
      ...pickBy(numbers, function (value, key) {
        return (
          value.start > NUMBER_LIST_DEFAULT[key as keyof typeof NUMBER_LIST_DEFAULT].start ||
          value.end < NUMBER_LIST_DEFAULT[key as keyof typeof NUMBER_LIST_DEFAULT].end
        );
      }),
    }, true);
    dispatch({
      type: resetSkip ? "SET_DATA" : "INCREASE_DATA",
      payload: {
        data: response.isSucceed ? response.data.result : [],
        allDataCount: response.isSucceed ? response.data.totalCount : 0,
        asPath: asPath
      },
    });
    if (resetSkip) {
      animatedScrollTop('instant');
    }
    loading.current = false;
  }
}

Contents.getInitialProps = async (context: NextPageContext) => {
  const query = context.query;
  const asPath = context.asPath;

  const mediaType = query.mediaType as string;
  let errorPage = false;
  if (
    mediaType &&
    !["film", "dizi", "icerikler"].includes(mediaType.toLowerCase())
  ) {
    return {
      errorPage: true,
    };
  }

  // get initial response
  // @ts-ignore
  const storedData = context.store.getState().generalReducer.data;
  // @ts-ignore
  const asPathWhenDataSaved = context.store.getState().generalReducer.asPathWhenDataSaved;
  if ((!storedData.length && !errorPage) || asPathWhenDataSaved !== asPath) {
    // media type
    // @ts-ignore
    const numbers: typeof NUMBER_LIST_DEFAULT = context.store.getState().generalReducer.filters.numbers;
    const response = await moviesGetByFilter(
      {
        pageSize: 50,
        page: 1,
        ...turnFilterQueryToApiObjects(query),
        ...pickBy(
          numbers,
          function (value, key) {
            return (
              value.start > NUMBER_LIST_DEFAULT[key as keyof typeof NUMBER_LIST_DEFAULT].start ||
              value.end < NUMBER_LIST_DEFAULT[key as keyof typeof NUMBER_LIST_DEFAULT].end
            );
          }
        ),
      },
      false,
      context
    );
    // @ts-ignore
    context.store.dispatch({
      type: "SET_DATA",
      payload: {
        data: response.isSucceed ? response.data.result : [],
        allDataCount: response.isSucceed ? response.data.totalCount : 0,
        asPath: asPath
      },
    });
  }

  if (errorPage) {
    redirectContextToStatus(context.res);
  }

  return {
    errorPage,
  };
};

export default Contents;
