/**
 *Created By Barbaros Susuz on Jun, 2019
 **/

import React from 'react';
import Error404 from "./404";

const Error = props => {
    const statusCode = props.statusCode ? props.statusCode : 404;
    return (
        <Error404/>
    )
};
Error.getInitialProps = async function ({res, err}) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return {statusCode}
};

export default Error;
