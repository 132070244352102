import {isBrowser} from "./common";
import type {MultiLanguageType} from "@/types/contentType";

export function runTimeConverter(object: {runtime: string, runtimeNumber: number}) {
    if ((!object.runtime || object?.runtime === 'N/A') && !object?.runtimeNumber) return '-';
    if (!object?.runtime || object?.runtime === 'N/A') {
        return `${object.runtimeNumber} dk`
    }
    const time = Number(object.runtime.replace(' min', ''));
    return time + ' dk';
}

export function animatedScrollTop(behavior = 'smooth' as ScrollBehavior) {
    if (isBrowser())
        window.scrollTo({
            top: 0,
            left: 0,
            behavior
        });
}
