import React from 'react';
import Link from 'next/link'
import Main from "../layout/Main";
import Button from "@/components/form/Button";
import {ArrowBackIcon} from "@/icons/index";

export default function Error404({statusCode = 404, ...restProps}: { statusCode: number }) {
    return (
        <Main {...restProps}>
            <section id='error' className='container-fluid container-max-width'>
                <div className='d-flex flex-wrap flex-column flex-lg-row'>
                    <div className='d-flex flex-column justify-content-center' style={{flex: 0.5}}>
                        <p className='header mb-2'>Opss...</p>
                        <p className='second-header mb-4'>Bir şeyler yanlış gitmiş olabilir</p>
                        <Link href="/">
                            <Button buttonType='primary' size='lg'>
                                <ArrowBackIcon/>
                                Ana sayfaya dön
                            </Button>
                        </Link>
                    </div>
                    <div className='position-relative w-100 h-100' style={{flex: 1}}>
                        <img
                            src="/images/404.png"
                            alt="nerede izlenir 404 error"
                        />
                    </div>
                </div>
            </section>
        </Main>
    )
}
